import http from '@/utils/http.js'

// 商机-报备（列表）
export function reportList(data) {
     return http({
          url: "/jasoboss/chance/list",
          method: "post",
          data
     })
}
// 商机-报备（单个）
export function reportGet(data) {
     return http({
          url: "/jasoboss/chance/get",
          method: "post",
          data
     })
}
// 商机-报备（修改）
export function reportUpdate(data) {
     return http({
          url: "/jasoboss/chance/update",
          method: "post",
          data
     })
}
// 商机-报备（添加）
export function reportAdd(data) {
     return http({
          url: "/jasoboss/chance/add",
          method: "post",
          data
     })
}
// 商机延期申请和审核
export function reportApplyAudit(data) {
     return http({
          url: "/jasoboss/chance/apply/audit",
          method: "post",
          data
     })
}
// 商机延期申请列表
export function reportApllyList(data) {
     return http({
          url: "/jasoboss/chance/apply/list",
          method: "post",
          data
     })
}
//
export function accountBrief(data) {
     return http({
          url: "/jasoboss/niche/account/brief/chance/get",
          method: "post",
          data
     })
}
// 商机-部门账号 设置显示
export function accountDepm(data) {
     return http({
          url: "/jasoboss/niche/account/brief/chance/department/get",
          method: "post",
          data
     })
}
// 商机-员工账号 设置显示
export function accountAdmin(data) {
     return http({
          url: "/jasoboss/niche/account/brief/chance/admin/get",
          method: "post",
          data
     })
}
// 商机-报备的时候查询最大的报备数和已报备的数量
export function accountGet(data) {
     return http({
          url: "/jasoboss/niche/account/brief/chance/admin/id/get",
          method: "post",
          data
     })
}
// 部门商机统计
export function nicheReportBrief(data) {
     return http({
          url: "/jasoboss/chance/report/brief",
          method: "post",
          data
     })
}
export function reportHistory(data) {
     return http({
          url: "/jasoboss/chance/history/list",
          method: "post",
          data
     })
}