<template>
    <div class="add">
        <div class="from-box">
            <div>
                <Basic ref="basic" />
            </div>
            <div style="margin-top: 8px">
                <Target ref="target" />
            </div>
        </div>
        <div class="bottom-but">
            <span style="margin-left: auto">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
    </div>
</template>
<script>
import Basic from './basic.vue';
import Target from './target.vue';
import { contracUpDate } from '@/api/contr/contr.js';
import { contracGet } from '@/api/contr/contr.js';
import { reportList } from '@/api/report/newReport';
import { reportList as oldReportList } from '@/api/report/report.js';
export default {
    components: {
        Basic,
        Target,
    },
    data() {
        return {
            data: {},
        };
    },
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            contracGet(data).then((res) => {
                this.data = res.data;

                let row = Object.assign({}, res.data);
                this.$refs.basic.getCustomerData(row);
                this.$refs.target.getData(res.data);
            });
        },
        async onSubmit() {
            let basic = this.$refs.basic.form;
            let target = this.$refs.target.data;
            let fieldList = this.$refs.target.fieldList;
            basic.contractAttachmentDOList = [];
            basic.customerId = this.$refs.basic.customerId;

            for (let i in this.$refs.basic.fileList) {
                basic.contractAttachmentDOList.push({
                    attachmentUrl: this.$refs.basic.fileList[i].url,
                });
            }

            if (!basic.customerId) {
                return this.$message.error('请选择客户');
            } else if (!basic.contractAmount) {
                return this.$message.error('请输入合同金额');
            } else if (!basic.contractA) {
                return this.$message.error('请输入甲方');
            } else if (!basic.typeB) {
                return this.$message.error('请选择乙方');
            } else if (basic.typeB == 9 && !basic.contractB) {
                return this.$message.error('请输入乙方签约');
            } else if (!basic.saleType) {
                return this.$message.error('请选择合同类型');
            } else {
                for (let i = 0; i < target.length; i++) {
                    if (!target[i].productCategory) {
                        return this.$message.error('请选择产品类别');
                    }

                    for (let j = 0; j < fieldList[i].length; j++) {
                        if (!target[i][fieldList[i][j].attributeColumnName]) {
                            if (
                                (target[i].productCategory == 1 ||
                                    target[i].productCategory == 2 ||
                                    target[i].productCategory == 4) &&
                                target[i].productType == 3 &&
                                fieldList[i][j].attributeColumnName == 'term'
                            ) {
                            } else {
                                return this.$message.error(
                                    fieldList[i][j].attributeName + '不能为空'
                                );
                            }
                        }
                    }
                }
                if (
                    this.$refs.target.$data.total !=
                    this.$refs.basic.$data.form.contractAmount
                ) {
                    return this.$message.error('合计金额应等于合同金额');
                }
                var data = {
                    param: {},
                };

                data.param.contractAttachmentDOList =
                    basic.contractAttachmentDOList;
                data.param = basic;
                data.param.contractTargetDOList = target;

                let aaa = Object.assign({}, basic);
                if (!aaa.lineId) {
                    data.param.lineId = 0;
                }
                data.param.id = this.data.id;
                data.param.remark = this.$refs.target.remark;
                data.param.contractAttachmentDOList =
                    basic.contractAttachmentDOList;
                data.param.businessLicenseUrl = basic.businessLicenseUrl;
                if (basic.saleType == 2) {
                    delete data.param.lineId;
                }
                let dataCheck = {
                    param: {
                        adminId: sessionStorage.getItem('adminId'),
                        customerId: basic.customerId,
                        reportStatusList: [3],
                    },
                };
                let resTM = await reportList(dataCheck),
                    resAli = await oldReportList({
                        param: {
                            adminId: sessionStorage.getItem('adminId'),
                            customerId: basic.customerId,
                            reportStatusList: [3],
                            productType: 2,
                        },
                    }),
                    resWy = await oldReportList({
                        param: {
                            adminId: sessionStorage.getItem('adminId'),
                            customerId: basic.customerId,
                            reportStatusList: [3],
                            productType: 4,
                        },
                    }),
                    tag = false,
                    termination = true;
                data.param.contractTargetDOList.forEach(async (item) => {
                    if (termination) {
                        if (
                            item.productType == 1 &&
                            item.productCategory == 1
                        ) {
                            // resTM = await reportList(dataCheck);
                            if (!resTM.data || resTM.data.total == 0) {
                                termination = false;
                                this.$message.error(
                                    '保存失败，当前客户未存在有效腾讯报备'
                                );
                                return false;
                            } else {
                                item.chanceId = resTM.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productType == 1 &&
                            item.productCategory == 2
                        ) {
                            // dataCheck.param.productType == 2;
                            // resAli = await oldReportList(dataCheck);
                            if (!resAli.data || resAli.data.total == 0) {
                                termination = false;
                                this.$message.error(
                                    '保存失败，当前客户未存在有效阿里报备'
                                );
                                return false;
                            } else {
                                item.nicheId = resAli.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productType == 1 &&
                            item.productCategory == 4
                        ) {
                            // dataCheck.param.productType == 4;
                            // resWy = await oldReportList(dataCheck);
                            if (!resWy.data || resWy.data.total == 0) {
                                termination = false;
                                this.$message.error(
                                    '保存失败，当前客户未存在有效网易报备'
                                );
                                return false;
                            } else {
                                item.nicheId = resWy.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productCategory == 1 ||
                            item.productCategory == 2 ||
                            item.productCategory == 4 ||
                            item.productCategory == 12
                        ) {
                            if (
                                item.productType == 2 ||
                                item.productType == 3
                            ) {
                                if (!item.dealCustomerId) {
                                    tag = true;
                                    termination = false;
                                    return false;
                                }
                            }
                        }
                    }
                });

                if (tag) {
                    return this.$message.error('请选择已购买产品');
                }
                if (
                    data.param.contractTargetDOList.some((item) => {
                        return item.term > 20;
                    })
                ) {
                    return this.$message.error('年限不允许大于20年');
                }
                if (!termination) {
                    return;
                }
                contracUpDate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.onClose();
                        this.$refs.basic.form = {
                            contractA: '',
                            representB: '',
                            contractAmount: 0,
                            representA: '',
                            signTime: '',
                            expireTime: '',
                            payWay: '',
                            contractB: '',
                            typeB: '',
                            businessLicenseUrl: '',
                            lineId: '',
                            saleType: 0,
                        };
                        this.$refs.basic.customerId = '';
                        this.$refs.basic.businessLicense = [];
                        this.$refs.basic.fileList = [];
                        this.$refs.basic.fileArr = [];
                        this.$refs.basic.lineId = '';
                        this.$refs.target.data = [
                            {
                                productCategory: '',
                                version: '',
                                productType: '',
                            },
                        ];
                        this.$refs.target.fieldList = [];
                        this.$refs.target.total = 0;
                        this.$refs.target.remark = '';
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.from-box {
    flex: 1;
    background: #f0f2f4;
    overflow: auto;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    button {
        width: 90px;
    }
}
</style>
