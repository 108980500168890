<template>
    <div class="basic">
        <div class="title_box">
            <p class="title"><span></span>合同标的</p>
            <button @click="onAdd()" class="cancel">
                <i class="el-icon-plus" style="font-size: 12px"></i>
                添加标的
            </button>
        </div>
        <div v-for="(form, index) in data">
            <div class="index">
                <span>标的{{ index + 1 }}</span>
                <span>
                    <el-button
                        type="text"
                        size="small"
                        v-if="data.length > 1"
                        @click="onDelete(form)"
                    >
                        <i class="el-icon-delete"></i>
                        删除</el-button
                    >
                </span>
            </div>
            <el-form label-width="75px" label-position="left" size="small">
                <div class="column_box">
                    <div class="column">
                        <el-form-item label="产品类别" required>
                            <el-cascader
                                v-model="form.productCategory"
                                clearable
                                :options="targetList"
                                :show-all-levels="false"
                                placeholder="请选择产品类别"
                                style="width: 240px; margin-right: 27px"
                                :props="{
                                    value: 'id',
                                    label: 'productName',
                                    children: 'children',
                                    emitPath: false,
                                }"
                                @change="
                                    (form.version = ''),
                                        (form.productType = ''),
                                        changeType(index, form.productCategory)
                                "
                            >
                            </el-cascader>
                        </el-form-item>
                        <div class="columns" v-if="form.isTrue">
                            <div
                                v-if="fieldList[index].length > 0"
                                v-for="i in fieldList[index]"
                                style="margin-right: 10px"
                            >
                                <span v-if="!i.isDis">
                                    <el-form-item
                                        :label="i.attributeName"
                                        required
                                    >
                                        <el-select
                                            v-if="
                                                i.productAttributeValueBOList &&
                                                i.productAttributeValueBOList
                                                    .length > 0
                                            "
                                            v-model="
                                                form[i.attributeColumnName]
                                            "
                                            placeholder="请选择"
                                            style="width: 240px"
                                            @change="
                                                getType(
                                                    i.attributeColumnName,
                                                    form,
                                                    index
                                                )
                                            "
                                        >
                                            <el-option
                                                v-for="(
                                                    item, j
                                                ) in i.productAttributeValueBOList"
                                                :key="j"
                                                :label="item.attributeValueName"
                                                :value="item.attributeValue"
                                            >
                                            </el-option>
                                        </el-select>
                                        <span v-else>
                                            <el-input
                                                v-model="
                                                    form[i.attributeColumnName]
                                                "
                                                placeholder="请输入"
                                                style="width: 240px"
                                                @change="getTotal()"
                                                @input="
                                                    (val) => {
                                                        onInput(
                                                            val,
                                                            i.attributeColumnName
                                                        );
                                                    }
                                                "
                                            ></el-input>
                                        </span>
                                    </el-form-item>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding: 0 30px">
                    <el-form-item label="明细">
                        <el-input
                            style="width: 570px"
                            type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="remark"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="form.isShowTable"
                        style="width: 50%; float: left"
                        label="产品ID"
                    >
                        {{
                            chooseTableDataList &&
                            chooseTableDataList.length > 0 &&
                            chooseTableDataList[index]
                                ? chooseTableDataList[index].identification ||
                                  '- -'
                                : '- -'
                        }}
                    </el-form-item>
                    <el-form-item
                        v-if="form.isShowTable"
                        style="width: 50%; float: left"
                        :label="form.productCategory == 1 ? 'CorpID' : '域名'"
                    >
                        {{
                            form.productCategory == 1
                                ? chooseTableDataList &&
                                  chooseTableDataList.length > 0 &&
                                  chooseTableDataList[index]
                                    ? chooseTableDataList[index].corpId || '- -'
                                    : '- -'
                                : chooseTableDataList &&
                                  chooseTableDataList.length > 0 &&
                                  chooseTableDataList[index]
                                ? chooseTableDataList[index].domain || '- -'
                                : '- -'
                        }}
                    </el-form-item>
                </div>
                <Table
                    ref="producttable"
                    v-if="form.isShowTable"
                    @chooseTable="chooseTable"
                ></Table>
            </el-form>
        </div>
        <div class="statistics">
            <div class="total">
                <p>
                    合计 <span>{{ total }}</span
                    >元
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import {
    productList,
    productAttributeRelationList,
} from '@/api/product/product.js';
import { closedCusList } from '@/api/closedCus/closedCus';
import Table from './productTab.vue';
export default {
    components: {
        Table,
    },
    data() {
        return {
            data: [
                {
                    productCategory: '',
                    version: '',
                    productType: '',
                    receivableAmount: '',
                    isTrue: false,
                    dealCustomerId: '',
                },
            ],
            remark: '',
            total: 0,
            chooseTableDataList: [],
            targetList: [],
            fieldList: [],
            isTermList: [],
            isShowTable: false,
        };
    },
    methods: {
        changeType(i, id) {
            let data = {
                param: {
                    productId: id,
                },
            };
            this.data[i].isTrue = false;
            productAttributeRelationList(data).then((res) => {
                if (res.code == 200) {
                    this.fieldList[i] = res.data.list.sort(
                        this.compare('reorder')
                    );

                    if (res.data.list.length > 0) {
                        this.data[i].isTrue = true;
                    } else {
                        this.data[i].isTrue = false;
                    }
                    this.data[i].isShowTable = false;
                    this.data = JSON.parse(JSON.stringify(this.data));
                }
            });
        },
        chooseTable(row, index) {
            this.chooseTableDataList[index] = row;
            this.data[index].dealCustomerId = row.id;
            this.chooseTableDataList = JSON.parse(
                JSON.stringify(this.chooseTableDataList)
            );
        },
        getType(type, row, i) {
            if (
                type == 'productType' &&
                (row.productCategory == 1 ||
                    row.productCategory == 2 ||
                    row.productCategory == 4)
            ) {
                for (let j in this.fieldList[i]) {
                    this.fieldList[i][j].isDis = false;
                    if (
                        row.productType == 3 &&
                        this.fieldList[i][j].attributeColumnName == 'term'
                    ) {
                        // this.fieldList[i].splice(j, 1);
                        this.fieldList[i][j].isDis = true;
                    }
                }
            }
            if (
                type == 'productType' &&
                (row.productCategory == 1 ||
                    row.productCategory == 2 ||
                    row.productCategory == 4 ||
                    row.productCategory == 12)
            ) {
                if (row.productType == 2 || row.productType == 3) {
                    this.closedCusList(row, i);
                    return;
                }
                this.data[i].isShowTable = false;
                this.data = JSON.parse(JSON.stringify(this.data));
            }
        },
        closedCusList(row, i) {
            if (!sessionStorage.getItem('nowCustomerId')) {
                return this.$message.error('请先选择客户');
            }
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            let data = {
                param: {
                    customerId: sessionStorage.getItem('nowCustomerId'),
                    type: row.productCategory,
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (!btn.renew) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            closedCusList(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.list.length == 0) {
                        row.productType = '';
                        this.data = JSON.parse(JSON.stringify(this.data));
                        return this.$message.error('当前客户无续费权限');
                    }
                    this.data[i].isShowTable = true;
                    this.data = JSON.parse(JSON.stringify(this.data));

                    setTimeout(() => {
                        this.$refs.producttable[i].getData(
                            res.data.list,
                            row.productCategory,
                            i,
                            this.data[i].productType
                        );
                    }, 0);
                }
            });
        },
        compare(p) {
            return function (m, n) {
                var a = m[p];
                var b = n[p];
                return a - b; //升序
            };
        },

        getData(row) {
            this.productList();
        },
        // 产品列表
        productList() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            productList(data).then((res) => {
                let dataArr = this.$Tree.treeDataTranslateAudit(
                    res.data.list.sort(this.compare('reorder')),
                    'id',
                    'parentId'
                );
                this.targetList = dataArr;
                for (let i in this.targetList) {
                    if (this.targetList[i].parentId != 0) {
                        this.targetList.splice(i, 1);
                    }
                }
            });
        },
        onAdd() {
            this.data.push({
                productCategory: '',
                version: '',
                productType: '',
                isTrue: false,
            });
        },
        onDelete(item) {
            var index = this.data.indexOf(item);
            if (index !== -1) {
                this.data.splice(index, 1);
            }
        },
        getTotal() {
            let price = this.data.reduce(
                (sum, e) => sum + Number(e.receivableAmount || 0),
                0
            );
            this.total = price;
        },
        onInput(val, name) {
            if (name == 'term') {
                if (Number(val) > 20) {
                    return this.$message.error('年限不允许大于20年');
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.basic {
    width: 100%;
    background: #fff;
    .title_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 16px;
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
            margin-right: 30px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .title {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        padding: 12px 0;
        span {
            background: #2370eb;
            width: 2px;
            height: 16px;
            display: inline-block;
            margin-right: 27px;
        }
    }
    .index {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        padding: 0 29px 12px 29px;
        span:nth-child(1) {
            color: #333333;
        }
        span:nth-child(2) {
            i {
                color: #da5120;
            }
            button {
                color: #666666;
            }
        }
    }
    .column_box {
        display: flex;
        padding: 0 30px;
    }
    .columns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        div {
            flex: 1;
        }
        .isDis {
            display: none;
        }
    }
    .line {
        display: flex;
        padding: 0 30px;
        .el-form-item--mini.el-form-item,
        .el-form-item--small.el-form-item {
            flex: 1;
        }
    }
    .statistics {
        display: flex;
        padding: 32px 20px 23px 20px;
        padding-bottom: 20px;
        div {
            background: #f0f4ff;
            border-radius: 7px;
        }
        .total {
            flex: 1;

            padding: 0 15px;

            p {
                font-size: 14px;
                color: #333333;
                margin: 12px 0;
                display: flex;
                align-items: center;
                span {
                    font-size: 20px;
                    font-weight: 600;
                    flex: 1;
                    text-align: right;
                    margin-right: 10px;
                }
            }
        }
        // .detailed {
        //     flex: 1;
        //     margin-left: 24px;
        //     padding-left: 15px;
        //     p:nth-child(1) {
        //         font-size: 14px;
        //         color: #333333;
        //         margin: 12px 0;
        //     }
        //     .el-textarea__inner {
        //         border: none !important;
        //     }
        // }
    }
}
.isDis {
    display: flex;
}
</style>
<style>
.detailed .el-textarea__inner {
    border: none !important;
    background: #f0f4ff;
    padding-left: 0;
}
</style>
