<template>
    <div class="basic">
        <p class="title"><span></span>基本资料</p>
        <el-form
            label-width="70px"
            label-position="left"
            size="small"
            ref="form"
        >
            <div class="column_box">
                <div class="column">
                    <el-form-item label="客户" required>
                        <span
                            v-show="disabled"
                            style="margin-left: 10px; font-size: 13px"
                            >{{ companyName }}</span
                        >
                        <el-select
                            v-show="!disabled"
                            :disabled="companyNamedisabled"
                            filterable
                            remote
                            :remote-method="remoteMethod"
                            v-model="customerId"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                            @change="getRepresentA()"
                        >
                            <el-option
                                v-for="item in customerData"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                                <span style="float: left">{{
                                    item.companyName
                                }}</span>
                                <span
                                    style="
                                        float: right;
                                        color: #8492a6;
                                        font-size: 13px;
                                    "
                                    >{{
                                        item.type == 1
                                            ? '线索'
                                            : item.type == 2
                                            ? '客户'
                                            : ''
                                    }}</span
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="甲方" required>
                        <el-input
                            :disabled="disabled"
                            v-model="form.contractA"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="甲方代表">
                        <el-input
                            :disabled="disabled"
                            v-model="form.representA"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="乙方" required>
                        <el-select
                            :disabled="disabled"
                            v-model="form.typeB"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="乙方代表">
                        <el-input
                            :disabled="disabled"
                            v-model="form.representB"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="乙方签约"
                        required
                        v-if="form.typeB == 9"
                    >
                        <el-input
                            :disabled="disabled"
                            v-model="form.contractB"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="结算部门"
                        v-if="departmentAdminDOList.length > 1"
                    >
                        <el-select
                            v-model="form.departmentId"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in departmentAdminDOList"
                                :key="item.departmentId"
                                :label="item.departmentName"
                                :value="item.departmentId"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营业执照">
                        <el-upload
                            :class="{ hide: hideUpload }"
                            :action="action"
                            class="avatar-uploader"
                            list-type="picture-card"
                            :on-success="onBusinessLicense"
                            :before-upload="beforeAvatarUploads"
                            multiple
                            drag
                            :limit="1"
                            ref="businessLicense"
                            accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                            :file-list="businessLicense"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                            <div
                                slot="file"
                                slot-scope="{ file }"
                                style="text-align: center"
                            >
                                <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="form.businessLicenseUrl"
                                    alt=""
                                    v-if="
                                        form.businessLicenseUrl &&
                                        (form.businessLicenseUrl.indexOf(
                                            '.jpg'
                                        ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.gif'
                                            ) != -1)
                                    "
                                />
                                <span
                                    v-if="
                                        form.businessLicenseUrl &&
                                        (form.businessLicenseUrl.indexOf(
                                            '.pdf'
                                        ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.PDF'
                                            ) != -1)
                                    "
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    v-if="
                                        form.businessLicenseUrl &&
                                        form.businessLicenseUrl.indexOf(
                                            '.doc'
                                        ) != -1
                                    "
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span class="el-upload-list__item-actions">
                                    <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)"
                                        v-if="
                                            form.businessLicenseUrl &&
                                            (form.businessLicenseUrl.indexOf(
                                                '.jpg'
                                            ) != -1 ||
                                                form.businessLicenseUrl.indexOf(
                                                    '.png'
                                                ) != -1 ||
                                                form.businessLicenseUrl.indexOf(
                                                    '.gif'
                                                ) != -1)
                                        "
                                    >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                    >
                                        <i class="el-icon-download"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleRemove(file)"
                                    >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>
                </div>
                <div class="column">
                    <el-form-item label="合同金额" required>
                        <el-input
                            :disabled="disabled"
                            v-model="form.contractAmount"
                            style="width: 240px"
                            placeholder="请输入"
                            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="签约时间">
                        <el-date-picker
                            :disabled="disabled"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.signTime"
                            style="width: 240px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="到期时间">
                        <el-date-picker
                            :disabled="disabled"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.expireTime"
                            style="width: 240px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="支付方式">
                        <el-select
                            :disabled="disabled"
                            v-model="form.payWay"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                            ><el-option label="网银" :value="1"></el-option>
                            <el-option label="现金" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合同类型" required>
                        <el-radio-group v-model="form.saleType">
                            <el-radio :label="1">线索</el-radio>
                            <el-radio :label="2">自拓</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="关联线索" v-show="form.saleType == 1">
                        <el-select
                            filterable
                            v-model="form.lineId"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请搜索选择关联线索"
                        >
                            <el-option
                                v-for="item in clueData"
                                :key="item.id"
                                :label="
                                    item.createTime +
                                    ' ' +
                                    $tableDataHandle.productCategory(
                                        item.productClue
                                    )
                                "
                                :value="item.id"
                            >
                                <span
                                    style="
                                        float: left;
                                        color: #8492a6;
                                        font-size: 13px;
                                        margin-right: 5px;
                                    "
                                    >{{
                                        item.createTime
                                            ? item.createTime.slice(0, 16)
                                            : '- -'
                                    }}</span
                                >
                                <span style="float: right">
                                    {{
                                        $tableDataHandle.productCategory(
                                            item.productClue
                                        )
                                    }}
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="附件">
                        <el-upload
                            :class="{ hide: hideUploads }"
                            drag
                            :action="action"
                            class="avatar-uploader"
                            list-type="picture-card"
                            :on-success="onAttachment"
                            :before-upload="beforeAvatarUploads"
                            multiple
                            :file-list="fileList"
                            :limit="limit"
                            ref="attachment"
                            accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                            <div
                                slot="file"
                                slot-scope="{ file }"
                                style="text-align: center"
                            >
                                <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="file.url"
                                    v-if="
                                        file.url &&
                                        (file.url.indexOf('.jpg') != -1 ||
                                            file.url.indexOf('.png') != -1 ||
                                            file.url.indexOf('.gif') != -1)
                                    "
                                />
                                <span
                                    v-if="
                                        file.url &&
                                        (file.url.indexOf('.pdf') != -1 ||
                                            file.url.indexOf('.PDF') != -1)
                                    "
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    v-if="
                                        file.url &&
                                        file.url.indexOf('.doc') != -1
                                    "
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span class="el-upload-list__item-actions">
                                    <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)"
                                        v-if="
                                            file.url &&
                                            (file.url.indexOf('.jpg') != -1 ||
                                                file.url.indexOf('.png') !=
                                                    -1 ||
                                                file.url.indexOf('.gif') != -1)
                                        "
                                    >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                    >
                                        <i class="el-icon-download"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleRemoves(file)"
                                    >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { customerList } from '@/api/contr/contr.js';
import { getCustomerId } from '@/api/user/ae/customer.js';
import { clueList } from '@/api/clue/meClue';
export default {
    data() {
        return {
            form: {
                contractA: '',
                representB: '',
                contractAmount: 0,
                representA: '',
                signTime: '',
                expireTime: '',
                payWay: '',
                contractB: '',
                typeB: '',
                lineId: '',
                businessLicenseUrl: '',
                saleType: 0,
            },
            companyName: '',
            customerData: [],
            action: this.$url.url() + '/file/local/upload/contract',
            fileList: [],
            businessLicense: [],
            contractAttachmentList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            limit: 5,
            fileArr: [],
            customerId: '',
            disabled: true,
            hideUpload: false,
            hideUploads: false,
            clueData: [],
            clueDisabled: false,
            companyNamedisabled: false,
            departmentAdminDOList: JSON.parse(
                sessionStorage.getItem('departmentAdminDOList')
            ),
        };
    },
    methods: {
        // 客户列表
        getCustomerData(row) {
            this.disabled = true;
            this.companyNamedisabled = false;
            this.hideUpload = false;
            this.hideUploads = false;
            this.form.businessLicenseUrl = row.businessLicenseUrl;
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
           
            if (row.adminId == sessionStorage.getItem('adminId')) {
                this.clueDisabled = false;
            } else {
                this.clueDisabled = true;
            }
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 10,
            };

            if (
                ((row.status == 11 || row.status == 8) &&
                    row.returnStatus == 1) ||
                btn.adminUpData
            ) {
                this.disabled = false;
                if (btn.adminUpData) {
                    this.companyNamedisabled = true;
                }
                customerList(data).then((res) => {
                    if (res.code == 200) {
                        res.data.list.push({
                            id: row.customerId,
                            companyName: row.companyName,
                        });
                        var obj = {};
                        this.customerData = res.data.list.reduce(
                            (prev, cur) => {
                                obj[cur.id]
                                    ? ''
                                    : (obj[cur.id] = true && prev.push(cur));
                                return prev;
                            },
                            []
                        );
                    }
                });
            }
            this.companyName = row.companyName;
            this.customerId = row.customerId;
            this.getClue();

            this.fileList = [];
            this.fileArr = [];
            this.form.contractA = row.contractA;
            this.form.typeB = row.typeB;
            this.form.contractB = row.contractB;
            this.form.contractAmount = row.contractAmount;
            this.form.representA = row.representA;
            this.form.representB = row.representB;
            this.form.signTime = row.signTime;
            this.form.expireTime = row.expireTime;
            this.form.payWay = row.payWay;
            this.form.departmentId = row.departmentId;
            this.form.lineId = row.lineId;
            this.form.saleType = row.saleType;
            if (this.form.lineId === 0) {
                this.form.lineId = '';
            }
            this.form.businessLicenseUrl = row.businessLicenseUrl;
            this.businessLicense = [];
            if (!row.businessLicenseUrl == false) {
                this.businessLicense = [{ url: row.businessLicenseUrl }];
                this.hideUpload = true;
            }
            this.contractAttachmentList = row.contractAttachmentBOList;
            this.fileArr = row.contractAttachmentBOList;
            if (
                row.contractAttachmentBOList &&
                row.contractAttachmentBOList.length > 0
            ) {
                for (let i in row.contractAttachmentBOList) {
                    this.fileList.push({ url: '' });
                    this.fileList[i].url =
                        row.contractAttachmentBOList[i].attachmentUrl;
                    this.fileArr[i].url =
                        row.contractAttachmentBOList[i].attachmentUrl;
                }
            }
            if (this.fileList.length == this.limit) {
                this.hideUploads = true;
            }
        },
        remoteMethod(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 10,
            };
            data.param.name = query;

            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerData = res.data.list;
                }
            });
        },
        getRepresentA() {
            if (this.customerId) {
                sessionStorage.setItem('nowCustomerId', this.customerId);
                let data = {
                    param: {
                        id: this.customerId,
                    },
                    pageNum: 0,
                    pageSize: 0,
                };
                getCustomerId(data).then((res) => {
                    if (res.code == 200) {
                        if (
                            res.data.customerContactList &&
                            res.data.customerContactList.length > 0
                        ) {
                            this.form.representA =
                                res.data.customerContactList[0].contactName;
                            this.form.contractA = res.data.companyName;
                        }
                    }
                });
            } else {
                this.form.representA = '';
                this.form.contractA = '';
            }
            this.form.lineId = '';
            this.getClue();
        },
        getClue() {
            let obj = {
                param: {
                    customerId: this.customerId,
                    customerStatusList: [1, 2, 3],
                },
                pageNum: 0,
                pageSize: 0,
            };
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            if (!btn.searchUser) {
                obj.param.followAdminId = sessionStorage.getItem('adminId');
            }
            clueList(obj).then((res) => {
                this.clueData = res.data.list;
            });
        },

        // 上传营业执照
        onBusinessLicense(response) {
            if ((response.code = 200)) {
                this.form.businessLicenseUrl = response.data;
                this.hideUpload = true;
                this.$message.success('营业执照上传成功');
            } else {
                this.$message.success('营业执照上传失败');
            }
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ url: response.data });
            if (this.fileList.length == this.limit) {
                this.hideUploads = true;
            }
            setTimeout(() => {
                this.fileList = this.fileArr;
                this.loading = false;
            }, 2000);
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // 营业执照删除
        handleRemove(file) {
            this.$refs['businessLicense'].uploadFiles = [];
            this.form.businessLicenseUrl = '';
            this.hideUpload = false;
        },
        // 附件删除
        handleRemoves(file) {
            for (let i in this.fileList) {
                if (this.fileList[i].url == file.url) {
                    this.fileArr.splice(i, 1);
                }
            }
            this.fileList = this.fileArr;
            this.hideUploads = false;
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.basic {
    width: 100%;
    background: #fff;
    .title {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        padding: 12px 0;
        span {
            background: #2370eb;
            width: 2px;
            height: 16px;
            display: inline-block;
            margin-right: 27px;
        }
    }
    .column_box {
        display: flex;
        padding: 0 30px;
    }
    .column {
        flex: 1;
    }
    .line {
        display: flex;
        padding: 0 30px;
        .el-form-item--mini.el-form-item,
        .el-form-item--small.el-form-item {
            flex: 1;
        }
    }
    /deep/ .avatar-uploader .el-upload {
        border: none;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #f3f6fb;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/.el-upload-dragger {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/ .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    /deep/.avatar-uploader-icon {
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 80px;
        text-align: center;
    }
    /deep/ .el-upload-list__item.is-success {
        width: 100px;
        height: 100px;
    }
}
.contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
    button {
        border: 2px solid #2370eb;
        background: #fff;
        color: #2370eb;

        padding: 2px;
        margin-left: 8px;
    }
}
</style>
<style>
.basic .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
}
</style>
