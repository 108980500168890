<template>
    <div class="add">
        <div class="from-box">
            <div>
                <Basic ref="basic" />
            </div>
            <div style="margin-top: 8px">
                <Target ref="target" />
            </div>
        </div>
        <div class="bottom-but">
            <span style="margin-left: auto">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
        <!-- 作废 -->
        <el-dialog
            :visible.sync="drawerVoid"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    合同确认
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                已存在相同客户名称和金额的合同， 是否继续创建？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subRevoke"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Basic from './basic.vue';
import Target from './target.vue';
import { contracAdd, contractList } from '@/api/contr/contr.js';
import { reportList } from '@/api/report/newReport';
import { reportList as oldReportList } from '@/api/report/report.js';
export default {
    components: {
        Basic,
        Target,
    },
    data() {
        return {
            drawerVoid: false,
            addData: {},
        };
    },
    methods: {
        getData() {
            Object.assign(this.$refs.target.$data, {
                data: [
                    {
                        productCategory: '',
                        version: '',
                        productType: '',
                        receivableAmount: '',
                        isTrue: false,
                    },
                ],
                remark: '',
                total: 0,
                targetList: [],
                fieldList: [],
                isTermList: [],
            });
            this.$refs.basic.getCustomerData();
            this.$refs.target.getData();
        },
        async onSubmit() {
            let basic = this.$refs.basic.form;
            let target = this.$refs.target.data;
            let fieldList = this.$refs.target.fieldList;

            basic.contractAttachmentDOList = [];
            basic.customerId = this.$refs.basic.customerId;
            for (let i in this.$refs.basic.fileList) {
                basic.contractAttachmentDOList.push({
                    attachmentUrl: this.$refs.basic.fileList[i].url,
                });
            }

            if (!basic.customerId) {
                return this.$message.error('请选择客户');
            } else if (!basic.contractAmount) {
                return this.$message.error('请输入合同金额');
            } else if (!basic.contractA) {
                return this.$message.error('请输入甲方');
            } else if (!basic.typeB) {
                return this.$message.error('请选择乙方');
            } else if (basic.typeB == 9 && !basic.contractB) {
                return this.$message.error('请输入乙方签约');
            } else if (!basic.saleType) {
                return this.$message.error('请选择合同类型');
            } else {
                for (let i = 0; i < target.length; i++) {
                    if (!target[i].productCategory) {
                        return this.$message.error('请选择产品类别');
                    }
                    for (let j = 0; j < fieldList[i].length; j++) {
                        if (!target[i][fieldList[i][j].attributeColumnName]) {
                            if (
                                (target[i].productCategory == 1 ||
                                    target[i].productCategory == 2 ||
                                    target[i].productCategory == 4) &&
                                target[i].productType == 3 &&
                                fieldList[i][j].attributeColumnName == 'term'
                            ) {
                            } else {
                                return this.$message.error(
                                    fieldList[i][j].attributeName + '不能为空'
                                );
                            }
                        }
                    }
                }

                if (
                    this.$refs.target.$data.total !=
                    this.$refs.basic.$data.form.contractAmount
                ) {
                    return this.$message.error('合计金额应等于合同金额');
                }
                let data = {
                    param: basic,
                };
                data.param.contractTargetDOList = target;
                data.param.remark = this.$refs.target.remark;
                if (basic.saleType == 2) {
                    delete data.param.lineId;
                }
                let obj = {
                    param: {
                        customerId: basic.customerId,
                        contractAmount: basic.contractAmount,
                        adminId: sessionStorage.getItem('adminId'),
                    },
                };

                let dataCheck = {
                    param: {
                        adminId: sessionStorage.getItem('adminId'),
                        customerId: basic.customerId,
                        reportStatusList: [3],
                    },
                };
                let resTM = await reportList(dataCheck);
                let resAli = await oldReportList({
                    param: {
                        adminId: sessionStorage.getItem('adminId'),
                        customerId: basic.customerId,
                        reportStatusList: [3],
                        productType: 2,
                    },
                });
                let resWy = await oldReportList({
                    param: {
                        adminId: sessionStorage.getItem('adminId'),
                        customerId: basic.customerId,
                        reportStatusList: [3],
                        productType: 4,
                    },
                });
                let tag = false;
                let termination = true;
                data.param.contractTargetDOList.forEach(async (item) => {
                    if (termination) {
                        console.log(123);
                        if (
                            item.productType == 1 &&
                            item.productCategory == 1
                        ) {
                            // resTM = ;
                            if (!resTM.data || resTM.data.total == 0) {
                                termination = false;
                                this.$message.error(
                                    '保存失败，当前客户未存在有效腾讯报备'
                                );
                                return false;
                            } else {
                                item.chanceId = resTM.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productType == 1 &&
                            item.productCategory == 2
                        ) {
                            dataCheck.param.productType == 2;
                            // resAli = ;
                            if (!resAli.data || resAli.data.total == 0) {
                                termination = false;

                                this.$message.error(
                                    '保存失败，当前客户未存在有效阿里报备'
                                );
                                return false;
                            } else {
                                item.nicheId = resAli.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productType == 1 &&
                            item.productCategory == 4
                        ) {
                            dataCheck.param.productType == 4;
                            // resWy = ;
                            if (!resWy.data || resWy.data.total == 0) {
                                termination = false;
                                this.$message.error(
                                    '保存失败，当前客户未存在有效网易报备'
                                );
                                return false;
                            } else {
                                item.nicheId = resWy.data.list[0].id;
                                delete item.dealCustomerId;
                            }
                        }
                        if (
                            item.productCategory == 1 ||
                            item.productCategory == 2 ||
                            item.productCategory == 4 ||
                            item.productCategory == 12
                        ) {
                            if (
                                item.productType == 2 ||
                                item.productType == 3
                            ) {
                                if (!item.dealCustomerId) {
                                    tag = true;
                                    termination = false;
                                    return false;
                                }
                                if(sessionStorage.getItem('userType') == 2){
                                    this.$message.error(
                                        '不允许创建续费和扩容单，请和经理联系'
                                    );
                                    tag = true;
                                    termination = false;
                                    return false;
                                };
                            }
                        }
                    }
                });

                if (tag) {
                    return this.$message.error('请选择已购买产品');
                }
                if (
                    data.param.contractTargetDOList.some((item) => {
                        return item.term > 20;
                    })
                ) {
                    return this.$message.error('年限不允许大于20年');
                }

                console.log(data);
                console.log(termination);
                if (!termination) {
                    return;
                }

                contractList(obj).then((res) => {
                    if (res.code == 200) {
                        if (!res.data.list || res.data.list.length == 0) {
                            this.add(data);
                        } else {
                            this.addData = data;
                            this.drawerVoid = true;
                        }
                    }
                });
            }
        },
        subRevoke() {
            this.add(this.addData);
        },
        add(data) {
            contracAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.$refs.basic.form = { representA: '' };
                    this.$refs.basic.customerId = '';
                    this.$refs.basic.businessLicense = [];
                    this.$refs.basic.fileList = [];
                    this.$refs.basic.fileArr = [];
                    this.$refs.basic.lineId = '';

                    this.$refs.target.fieldList = [];
                    this.$refs.target.total = 0;
                    this.$refs.target.remark = '';
                    this.onClose();
                }
            });
        },

        handleClose() {
            this.drawerVoid = false;
            this.addData = {};
        },
        onClose() {
            this.$emit('close');
            this.drawerVoid = false;
            this.addData = {};
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.from-box {
    flex: 1;
    background: #f0f2f4;
    overflow: auto;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    button {
        width: 90px;
    }
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
</style>
