<template>
    <div class="conlist">
        <div class="search-box">
            <div class="tab_box" style="padding: 12px 0">
                <p>
                    <span>时间</span>
                    <el-date-picker
                        v-model="searchVal.createDate"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择月"
                        size="small"
                        style="width: 150px"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                    </el-date-picker>
                </p>
                <p style="position: relative">
                    <span>回款状态</span>
                    <el-radio-group
                        size="small"
                        v-model="searchVal.returnStatusVal"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button label="待回款"></el-radio-button>
                        <el-radio-button label="待建计划"></el-radio-button>
                    </el-radio-group>
                    <span v-if="returnData > 0" class="totalData">{{
                        returnData
                    }}</span>
                </p>
                <p>
                    <Department
                        ref="department"
                        :departmentWidth="120"
                        :adminWidth="120"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.searchUser"
                        @searchData="searchData"
                    ></Department>
                </p>
                <p style="flex: 1">
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="请输入合同编号/客户名称"
                        v-model="searchVal.nameVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="
                            (currentPage = 1),
                                (pagesize = 20),
                                (listType = ''),
                                getTableData()
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p class="float:right">
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="
                            (currentPage = 1),
                                (pagesize = 20),
                                (listType = ''),
                                getTableData()
                        "
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 10px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div
                class="search-two"
                v-if="
                    (btnP.searchUser &&
                        !btnP.depSearch &&
                        searchVal.adminVal) ||
                    (!btnP.searchUser && !btnP.depSearch) ||
                    (btnP.searchUser && btnP.depSearch && searchVal.adminVal)
                "
            >
                <p>
                    <span>合同</span>
                    <el-radio-group
                        v-model="searchVal.adminValText"
                        size="small"
                    >
                        <el-radio-button
                            label="我的"
                            @click.native.prevent="clickBtnTime('我的')"
                        ></el-radio-button>
                        <el-radio-button
                            label="合作"
                            @click.native.prevent="clickBtnTime('合作')"
                        ></el-radio-button>
                        <el-radio-button
                            v-if="btnP.renew"
                            label="协作"
                            @click.native.prevent="clickBtnTime('协作')"
                        ></el-radio-button>
                    </el-radio-group>
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>我的合同</p>
            <span>
                <!--  -->
                <button @click="onAddData()" v-if="btnP.add" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    新增合同
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="创建时间" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同编号" width="135" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status != 3"
                        type="text"
                        class="isButColor"
                        @click="onDetails(scope.row)"
                        >{{ scope.row.contractNo }}</el-button
                    >
                    <span v-if="scope.row.status == 3" class="isRed">{{
                        scope.row.contractNo
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status != 3 && btnP.cusDetail"
                        type="text"
                        class="isButColor"
                        @click="onCustomerDetails(scope.row)"
                        >{{ scope.row.companyName }}</el-button
                    >
                    <span v-if="scope.row.status == 3" class="isRed">{{
                        scope.row.companyName
                    }}</span>
                    <span v-if="scope.row.status != 3 && !btnP.cusDetail">
                        {{ scope.row.companyName }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column label="联系人" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.representA
                    }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="合同金额" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.contractAmount
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column label="签约时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.signTime ? scope.row.signTime.slice(0, 10) : '- -'
                    }}</span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="到期时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.expireTime ? scope.row.expireTime.slice(0, 10) : '- -'
                    }}</span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="合同状态" prop="signTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.status == 3
                            ? '作废'
                            : scope.row.status == 7
                            ? '审核中'
                            : scope.row.status == 8
                            ? '审核驳回'
                            : scope.row.status == 10
                            ? '已归档'
                            : scope.row.status == 11
                            ? '未归档'
                            : '待归档'
                    }}</span>
                </template>
            </el-table-column> -->
            <el-table-column
                label="回款状态"
                prop="status"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.returnStatus == 1
                            ? '待建计划'
                            : scope.row.returnStatus == 2
                            ? '未到款'
                            : scope.row.returnStatus == 3
                            ? '部分到款'
                            : '全部到款'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开票状态"
                prop="status"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        getInvoicing(scope.row)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column label="开票金额" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        billing(scope.row)
                    }}</span>
                </template>
            </el-table-column> -->
            <el-table-column
                label="部门"
                v-if="btnP.depSearch"
                width="80"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-tooltip placement="top">
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                            returnName(scope.row)
                        }}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column v-if="btnP.depSearch" min-width="20">
            </el-table-column>
            <el-table-column
                label="所属人"
                width="55"
                v-if="btnP.searchUser"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span :class="scope.row.status == 3 ? 'isRed' : ''">{{
                        scope.row.adminName
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20" v-if="btnP.searchUser">
            </el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        v-if="
                            (btnP.upData &&
                                (scope.row.status == 11 ||
                                    scope.row.status == 12 ||
                                    scope.row.status == 8)) ||
                            btnP.adminUpData
                        "
                        @click="onUpData(scope.row)"
                        >编辑</el-button
                    >

                    <el-button
                        @click="onFile(scope.row)"
                        type="text"
                        style="color: #2370eb"
                        v-show="
                            btnP.file &&
                            (scope.row.status == 11 ||
                                scope.row.status == 12 ||
                                scope.row.status == 8)
                        "
                        >归档</el-button
                    >
                    <!-- <el-button
                        type="text"
                        style="color:#2370EB"
                        v-show="btnP.revoke && scope.row.status == 7"
                        @click="onRevoke(row)"
                        >撤销审核</el-button
                    > -->
                    <el-dropdown
                        @command="handleCommand"
                        style="margin-left: 10px"
                        trigger="click"
                        v-show="
                            (btnP.addInvoice || btnP.addMoney) &&
                            (scope.row.billingStatus != 3 ||
                                scope.row.returnStatus != 4) &&
                            scope.row.status != 3
                        "
                    >
                        <span class="el-dropdown-link">
                            <i
                                class="iconfont icon-gengduo"
                                style="color: #2370eb; font-weight: 600"
                            ></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            style="margin-left: 10px; text-align: center"
                        >
                            <el-dropdown-item
                                v-show="
                                    btnP.addMoney && scope.row.returnStatus != 4
                                "
                                :command="{ i: 1, row: scope.row }"
                                >回款</el-dropdown-item
                            >
                            <el-dropdown-item
                                v-show="
                                    btnP.addInvoice &&
                                    scope.row.billingStatus != 3
                                "
                                :command="{ i: 2, row: scope.row }"
                                >发票</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增合同 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleAdd"
            size="720px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增合同
                </div>
            </template>
            <AddContr @close="handleAdd" ref="add" />
        </el-drawer>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerUpData"
            :direction="direction"
            :before-close="handleUpData"
            size="720px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑合同
                </div>
            </template>
            <UpDataContr @close="handleUpData" ref="upData" />
        </el-drawer>
        <!-- 归档-->
        <el-dialog
            :visible.sync="drawerFile"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    合同归档
                </div>
            </template>
            <div class="content">是否归档该合同？</div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import AddContr from './addContr/addContr.vue';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import { contractList, customerTotal } from '@/api/contr/contr.js';
import UpDataContr from './editCont/upDataContr.vue';
import { contracUpDate } from '@/api/contr/contr.js';
import Department from '../../../components/Department';
export default {
    components: {
        AddContr,
        UpDataContr,
        Department,
    },
    data() {
        return {
            btnP: {},
            searchVal: {
                createDate: [],
                returnStatusVal: '全部',
                dempVal: '',
                adminVal: '',
                nameVal: '',
                adminValText: '我的',
            },
            isPage: false,
            dempData: [],
            userData: [],
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            data: {},
            drawerAdd: false,
            drawerUpData: false,
            drawerFile: false,
            direction: 'rtl',
            listType: '待归档',
            pageData: {},
            returnData: 0,
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            let data = JSON.parse(sessionStorage.getItem('pageData')).param;
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;

            if (data.returnStatusList && data.returnStatusList.length == 1) {
                this.searchVal.returnStatusVal = '待建计划';
            }
            if (data.returnStatusList && data.returnStatusList.length == 2) {
                this.searchVal.returnStatusVal = '待回款';
            }
            if (data.returnStatusList && data.returnStatusList.length == 4) {
                this.searchVal.returnStatusVal = '全部';
            }
            this.searchVal.nameVal = data.name;
            this.searchVal.adminVal = data.adminId;
            this.searchVal.dempVal = data.departmentId;
            this.$refs.department.$data.departmentId =
                data.departmentId || undefined;
            this.$refs.department.$data.adminId = data.adminId || '';
            if (data.adminId) {
                this.$refs.department.getUserList(data.departmentId);
            }
            this.searchVal.createDate = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param.createDateMonth;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
            this.searchVal.createDate =
                this.$searchTime.DateUtil.getStartDayOfMonth().slice(0, 7);
        }
        if (
            sessionStorage.getItem('listType') &&
            JSON.parse(sessionStorage.getItem('listType'))
        ) {
            this.listType = JSON.parse(sessionStorage.getItem('listType'));
            sessionStorage.removeItem('listType');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        if (sessionStorage.getItem('searchCompanyName')) {
            this.searchVal.createDate = [];
            this.searchVal.nameVal =
                sessionStorage.getItem('searchCompanyName');
        }
        if (sessionStorage.getItem('contractNo')) {
            this.searchVal.createDate = [];
            this.searchVal.nameVal = sessionStorage.getItem('contractNo');
            sessionStorage.removeItem('contractNo');
        }
        if (sessionStorage.getItem('isAchievement')) {
            this.searchVal.createDate =
                this.$searchTime.DateUtil.getStartDayOfMonth().slice(0, 7);
            this.searchVal.returnStatusVal = '待回款';
            sessionStorage.removeItem('isAchievement');
        }

        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.searchUser && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.depSearch) {
                this.getDempData();
            }

            this.getTableData();
        },

        clickBtnTime(e) {
            this.searchVal.adminValText = e;
            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(this.isStsts);
        },

        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.dempVal = data.departmentId;
            this.searchVal.adminVal = data.adminId;
            this.getTableData();
        },

        getTableData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch && this.btnP.searchUser) {
                if (this.searchVal.adminValText == '我的') {
                    data.param.departmentId =
                        sessionStorage.getItem('departmentId');
                    data.param.adminId = this.searchVal.adminVal;
                } else if (this.searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId = this.searchVal.adminVal;
                } else if (this.searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId = this.searchVal.adminVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.departmentId = this.searchVal.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.searchUser) {
                if (this.searchVal.adminValText == '我的') {
                    data.param.departmentId = this.searchVal.dempVal;
                    data.param.adminId = this.searchVal.adminVal;
                } else if (this.searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId = this.searchVal.adminVal;
                } else if (this.searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId = this.searchVal.adminVal;
                }
            }
            if (!this.btnP.depSearch && !this.btnP.searchUser) {
                if (this.searchVal.adminValText == '我的') {
                    data.param.adminId = sessionStorage.getItem('adminId');
                } else if (this.searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId =
                        sessionStorage.getItem('adminId');
                } else if (this.searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId =
                        sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.nameVal) {
                data.param.name = this.searchVal.nameVal;
            }

            if (
                this.searchVal.createDate &&
                this.searchVal.createDate.length > 0
            ) {
                data.param.createDateMonth = this.searchVal.createDate;
            }

            data.param.statusList = [10, 12, 8, 7, 11];

            if (this.searchVal.nameVal) {
                data.param.name = this.searchVal.nameVal;
            }

            if (this.searchVal.statusVal) {
                data.param.statusList = [this.searchVal.statusVal];
            }
            if (this.searchVal.returnStatusVal == '全部') {
                data.param.returnStatusList = [1, 2, 3, 4];
            }
            if (this.searchVal.returnStatusVal == '待回款') {
                data.param.returnStatusList = [2, 3];
            }
            if (this.searchVal.returnStatusVal == '待建计划') {
                data.param.returnStatusList = [1];
            }
            // if (this.searchVal.billingStatusVal) {
            //     data.param.billingStatus = this.searchVal.billingStatusVal;
            // }、

            this.pageData = data;
            contractList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
            this.getcustomerTotal(data);
        },
        getcustomerTotal(data) {
            delete data.param.collaboratorAdminId;
            delete data.param.cooperationAdminId;
            let obj = JSON.parse(JSON.stringify(data));
            obj.param.adminId =
                this.searchVal.adminVal || sessionStorage.getItem('adminId');
            obj.param.returnStatus = 1;
            customerTotal(obj).then((res) => {
                this.returnData = res.data;
                // this.returnData = 1;
            });
        },
        getInvoicing(data) {
            let nub = 0;
            if (
                data.contractInvoiceBOList &&
                data.contractInvoiceBOList.length > 0
            ) {
                data.contractInvoiceBOList.forEach((item) => {
                    if (item.status == 5) {
                        nub += item.invoiceAmount;
                    }
                });
            }
            if (nub > 0 && nub == data.contractAmount) {
                return '全部开票';
            }
            if (nub > 0 && nub != data.contractAmount) {
                return '部分开票';
            }
            if (nub == 0) {
                return '未开票';
            }
        },
        // 开票金额
        billing(row) {
            let nub = 0;
            if (
                row.contractReturnPlanBOList &&
                row.contractReturnPlanBOList.length > 0
            ) {
                for (let i = 0; i < row.contractReturnPlanBOList.length; i++) {
                    if (row.contractReturnPlanBOList[i].billingStatus == 2) {
                        nub += Number(
                            row.contractReturnPlanBOList[i].returnAmount
                        );
                    }
                }
            }
            return nub;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        returnStatus(e) {
            e === this.searchVal.returnStatusVal
                ? (this.searchVal.returnStatusVal = '')
                : (this.searchVal.returnStatusVal = e);
            this.getTableData();
        },
        returnName(row) {
            var str = '';
            row.departmentAdminDOList
                ? row.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        // 时间输入框变化
        expireTime() {
            this.searchVal.createTime = '';
            if (
                this.searchVal.createDate &&
                this.searchVal.createDate.length > 0
            ) {
                this.searchVal.createTime = '';
            }
            this.getTableData();
        },
        // 新增
        onAddData() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getData();
            });
        },
        handleAdd() {
            this.drawerAdd = false;
            this.getTableData();
        },
        // 编辑
        onUpData(row) {
            this.drawerUpData = true;
            setTimeout(() => {
                this.$refs.upData.getData(row);
            }, 100);
        },
        handleUpData() {
            this.drawerUpData = false;
            this.getTableData();
        },
        // 归档
        onFile(row) {
            this.data = row;
            this.drawerFile = true;
        },
        handleClose() {
            this.drawerFile = false;
            this.getTableData();
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 7,
                },
            };
            contracUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('归档成功');
                    this.handleClose();
                }
            });
        },
        // 撤销
        onRevoke(row) {
            let data = {
                param: {},
            };
        },
        // 新增回款
        handleCommand(obj) {
            if (obj.i == 1) {
                this.$router.push({
                    path: '/addReturnPlan',
                    query: {
                        id: obj.row.id,
                        disabled: false,
                    },
                });
            }
            if (obj.i == 2) {
                this.$router.push({
                    path: '/addApplyinvoice',
                    query: {
                        contracId: obj.row.id,
                        companyName: obj.row.companyName,
                        customerId: obj.row.customerId,
                    },
                });
            }
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            sessionStorage.setItem('listType', JSON.stringify(this.listType));
        },
        // 详情
        onDetails(row) {
            this.$router.push({
                path: '/condetails',
                query: {
                    id: row.id,
                    index: 1,
                },
            });

            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            sessionStorage.setItem('listType', JSON.stringify(this.listType));
        },
        onCustomerDetails(row) {
            row.id = row.customerId;
            if (this.btnP.cusDetail) {
                this.$router.push('/customerdetails');
                this.$formDetails.commit('detailsData', JSON.stringify(row));
                this.$choiceLabel.commit('getType', '2');
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
                console.log(this.pageData);
                sessionStorage.setItem(
                    'listType',
                    JSON.stringify(this.listType)
                );
            } else {
                return this.$message.error('暂无权限');
            }
        },
        // 重置
        searchEmpty() {
            this.searchVal = { createTime: '', createDate: [] };
            this.listType = '待归档';
            this.getTableData();
        },
    },
    destroyed() {
        sessionStorage.removeItem('searchCompanyName');
    },
};
</script>

<style lang="scss" scoped>
.conlist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        background: #fff;
        border-radius: 4px;
        font-size: 13px;
        width: 100%;
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            margin-right: 16px;
        }
    }
    .right {
        padding-top: 12px;
        white-space: nowrap;
    }
    .tab_box {
        display: flex;
        flex-flow: wrap;
        p {
            white-space: nowrap;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.search-two {
    padding-bottom: 12px;
}
.content {
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 16px;
    color: #333;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.isRed {
    color: red;
}
.isButColor {
    color: #2370eb;
}
.totalData {
    background: red;
    color: #fff !important;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    line-height: 25px;
    text-align: center;
    position: absolute;
    right: -25px;
    top: -10px;
}
</style>
