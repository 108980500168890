var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic"},[_c('div',{staticClass:"title_box"},[_vm._m(0),_c('button',{staticClass:"cancel",on:{"click":function($event){return _vm.onAdd()}}},[_c('i',{staticClass:"el-icon-plus",staticStyle:{"font-size":"12px"}}),_vm._v(" 添加标的 ")])]),_vm._l((_vm.data),function(form,index){return _c('div',[_c('div',{staticClass:"index"},[_c('span',[_vm._v("标的"+_vm._s(index + 1))]),_c('span',[(_vm.data.length > 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onDelete(form)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" 删除")]):_vm._e()],1)]),_c('el-form',{attrs:{"label-width":"75px","label-position":"left","size":"small"}},[_c('div',{staticClass:"column_box"},[_c('div',{staticClass:"column"},[_c('el-form-item',{attrs:{"label":"产品类别","required":""}},[_c('el-cascader',{staticStyle:{"width":"240px","margin-right":"27px"},attrs:{"clearable":"","options":_vm.targetList,"show-all-levels":false,"placeholder":"请选择产品类别","props":{
                                value: 'id',
                                label: 'productName',
                                children: 'children',
                                emitPath: false,
                            }},on:{"change":function($event){(form.version = ''),
                                    (form.productType = ''),
                                    _vm.changeType(index, form.productCategory)}},model:{value:(form.productCategory),callback:function ($$v) {_vm.$set(form, "productCategory", $$v)},expression:"form.productCategory"}})],1),(form.isTrue)?_c('div',{staticClass:"columns"},_vm._l((_vm.fieldList[index]),function(i){return (_vm.fieldList[index].length > 0)?_c('div',{staticStyle:{"margin-right":"10px"}},[(!i.isDis)?_c('span',[_c('el-form-item',{attrs:{"label":i.attributeName,"required":""}},[(
                                            i.productAttributeValueBOList &&
                                            i.productAttributeValueBOList
                                                .length > 0
                                        )?_c('el-select',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.getType(
                                                i.attributeColumnName,
                                                form,
                                                index
                                            )}},model:{value:(
                                            form[i.attributeColumnName]
                                        ),callback:function ($$v) {_vm.$set(form, i.attributeColumnName, $$v)},expression:"\n                                            form[i.attributeColumnName]\n                                        "}},_vm._l((i.productAttributeValueBOList),function(item,j){return _c('el-option',{key:j,attrs:{"label":item.attributeValueName,"value":item.attributeValue}})}),1):_c('span',[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"},on:{"change":function($event){return _vm.getTotal()},"input":function (val) {
                                                    _vm.onInput(
                                                        val,
                                                        i.attributeColumnName
                                                    );
                                                }},model:{value:(
                                                form[i.attributeColumnName]
                                            ),callback:function ($$v) {_vm.$set(form, i.attributeColumnName, $$v)},expression:"\n                                                form[i.attributeColumnName]\n                                            "}})],1)],1)],1):_vm._e()]):_vm._e()}),0):_vm._e()],1)]),_c('div',{staticStyle:{"padding":"0 30px"}},[_c('el-form-item',{attrs:{"label":"明细"}},[_c('el-input',{staticStyle:{"width":"570px"},attrs:{"type":"textarea","rows":3,"placeholder":"请输入内容"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1),(form.isShowTable)?_c('el-form-item',{staticStyle:{"width":"50%","float":"left"},attrs:{"label":"产品ID"}},[_vm._v(" "+_vm._s(_vm.chooseTableDataList && _vm.chooseTableDataList.length > 0 && _vm.chooseTableDataList[index] ? _vm.chooseTableDataList[index].identification || '- -' : '- -')+" ")]):_vm._e(),(form.isShowTable)?_c('el-form-item',{staticStyle:{"width":"50%","float":"left"},attrs:{"label":form.productCategory == 1 ? 'CorpID' : '域名'}},[_vm._v(" "+_vm._s(form.productCategory == 1 ? _vm.chooseTableDataList && _vm.chooseTableDataList.length > 0 && _vm.chooseTableDataList[index] ? _vm.chooseTableDataList[index].corpId || '- -' : '- -' : _vm.chooseTableDataList && _vm.chooseTableDataList.length > 0 && _vm.chooseTableDataList[index] ? _vm.chooseTableDataList[index].domain || '- -' : '- -')+" ")]):_vm._e()],1),(form.isShowTable)?_c('Table',{ref:"producttable",refInFor:true,on:{"chooseTable":_vm.chooseTable}}):_vm._e()],1)],1)}),_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"total"},[_c('p',[_vm._v(" 合计 "),_c('span',[_vm._v(_vm._s(_vm.total))]),_vm._v("元 ")])])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span'),_vm._v("合同标的")])}]

export { render, staticRenderFns }